import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../maincomponents/Footer";
import Header from "../../maincomponents/Header";
import Filter from "./Filter";
import Spinner from "./Spinner";
import SafeHtmlComponent from "./SafeHtmlComponent";
import { useBreadcrumb } from "./BreadcrumbContext";
import Breadcrumbs from "./Breadcrumbs";
import { Helmet } from "react-helmet";

const BrandProduct = () => {
  const navigate = useNavigate();
  const { brandId, brandProductTypeId } = useParams();
  const [brandProductData, setBrandProductData] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentView, setCurrentView] = useState("brandProducts");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedProductType, setSelectedProductType] =
    useState(brandProductTypeId);
  const [selectedBrand, setSelectedBrand] = useState(brandId);
  const [brandProducts, setBrandProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const { updateBreadcrumbs } = useBreadcrumb(); // Use breadcrumb context
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const BrandProductTitle =
    data.length > 0 && data[0].category ? data[0].brand.name : "BrandProduct";
  useEffect(() => {
    const fetchBrandProductData = async () => {
      try {
        const response = await axios.get(
          `https://saaskin.com/api/api/brand_products/${brandId}/${brandProductTypeId}/`
        );
        setData(response.data);
        setFilteredData(response.data);
        setLoading(false);
        setBrandProductData(response.data);

        if (response.data.length > 0) {
          const category = response.data[0].category;
          const subcategory = response.data[0].subcategory;
          const product_type = response.data[0].product_type;
          const brand = response.data[0].brand;
          const initialBreadcrumbs = [
            { name: "Home", path: "/" },
            { name: category.name, path: `/category` },
            { name: subcategory.name, path: `/subcategory/${category.id}` },
            {
              name: product_type.name,
              path: `/product-type/${subcategory.id}`,
            },
            { name: brand.name, path: `/brand/${product_type.id}` },
            { name: "", path: `/BrandProduct/` },
          ];
          updateBreadcrumbs(initialBreadcrumbs);
          setBreadcrumbs(initialBreadcrumbs);
        }
      } catch (error) {
        console.error("Error fetching product type data:", error);
        setLoading(false);
      }
    };
    fetchBrandProductData();
  }, [brandId, brandProductTypeId]);

  const handleFilterChange = async (type, id, name) => {
    try {
      let response;
      switch (type) {
        case "category":
          setSelectedCategory(id);
          setCurrentView("subcategory");
          response = await axios.get(
            `https://saaskin.com/api/api/subcategory/${id}`
          );
          setFilteredData(response.data);
          updateBreadcrumbs([
            { name: "Home", path: "/" },
            // { name: "Category", path: `/category/${id}` },
            { name: name, path: `/subcategory/${id}` },
          ]);
          setBreadcrumbs([
            { name: "Home", path: "/" },
            // { name: "Category", path: `/category/${id}` },
            { name: name, path: `/subcategory/${id}` },
          ]);
          break;
        case "subcategory":
          setSelectedSubCategory(id);
          setCurrentView("productType");
          response = await axios.get(
            `https://saaskin.com/api/api/product_type/${id}`
          );
          setFilteredData(response.data);
          const updatedSubcategoryBreadcrumbs = [
            ...breadcrumbs,
            { name, path: `/product-type/${id}` },
          ];
          updateBreadcrumbs(updatedSubcategoryBreadcrumbs);
          setBreadcrumbs(updatedSubcategoryBreadcrumbs);
          break;
        case "productType":
          setSelectedProductType(id);
          setCurrentView("brand");
          response = await axios.get(`https://saaskin.com/api/api/brand/${id}`);
          setFilteredData(response.data);
          const updatedProductTypeBreadcrumbs = [
            ...breadcrumbs,
            { name, path: `/brand/${id}` },
          ];
          updateBreadcrumbs(updatedProductTypeBreadcrumbs);
          setBreadcrumbs(updatedProductTypeBreadcrumbs);
          break;
        case "brand":
          setSelectedBrand(id);
          setCurrentView("brandProducts");
          response = await axios.get(
            `https://saaskin.com/api/api/brand_products/${id}/${selectedProductType}`
          );
          setBrandProducts(response.data);
          const updatedBrandBreadcrumbs = [
            ...breadcrumbs,
            {
              name,
              path: `/brand-product/${id}/${selectedProductType}`,
            },
          ];
          updateBreadcrumbs(updatedBrandBreadcrumbs);
          setBreadcrumbs(updatedBrandBreadcrumbs);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Error fetching data for ${type}:`, error);
    }
  };

  const handleProductClick = (productId, slug) => {
    navigate(`/product-detail/${productId}/${slug}/`);
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <Header />

      <Helmet>
        <title>{BrandProductTitle}</title>
        <meta
          name="description"
          content={`Explore products in ${BrandProductTitle}. Discover a wide range of high-quality products tailored to your needs.`}
        />
        <meta
          name="keywords"
          content={`${BrandProductTitle}, Wondfo Finecare Fia Meter Plus, SAASKIN Corporation, Healthcare products`}
        />
        <link
          rel="canonical"
          href={`https://saaskin.com/api/api/brand_products/${brandId}/${brandProductTypeId}/`}
        />
        <meta property="og:title" content={BrandProductTitle} />
        <meta
          property="og:description"
          content={`Explore ${BrandProductTitle}. Discover a wide range of high-quality products from SAASKIN Corporation.`}
        />
        <meta
          property="og:image"
          content="https://saaskin.com/images/saaskin_title_logo.png"
        />
        <meta
          property="og:url"
          content={`https://saaskin.com/api/api/brand_products/${brandId}/${brandProductTypeId}/`}
        />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "SAASKIN Corporation",
            "url": "https://saaskin.com",
            "logo": "https://saaskin.com/images/saaskin_title_logo.png",
            "sameAs": [
              "https://www.facebook.com/yourpage",
              "https://www.instagram.com/yourprofile",
              "https://www.linkedin.com/company/yourcompany",
              "https://twitter.com/yourprofile"
            ]
          }`}
        </script>
      </Helmet>
      <Container>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row className="mb-5">
          <Filter onFilterChange={handleFilterChange} />
          <Col>
            <Row>
              {currentView === "category" &&
                filteredData.map((category) => (
                  <Col key={category.id} xs={12} md={4} lg={3}>
                    <CategoryCard
                      category={category}
                      onClick={() =>
                        handleFilterChange(
                          "category",
                          category.id,
                          category.name
                        )
                      }
                    />
                  </Col>
                ))}
              {currentView === "subcategory" &&
                filteredData.map((subcategory) => (
                  <Col key={subcategory.id} xs={12} md={4} lg={4}>
                    <SubCategoryCard
                      subcategory={subcategory}
                      onClick={() =>
                        handleFilterChange(
                          "subcategory",
                          subcategory.id,
                          subcategory.name
                        )
                      }
                    />
                  </Col>
                ))}
              {currentView === "productType" &&
                filteredData.map((productType) => (
                  <Col key={productType.id} xs={12} md={4} lg={3}>
                    <ProductTypeCard
                      productType={productType}
                      onClick={() =>
                        handleFilterChange(
                          "productType",
                          productType.id,
                          productType.name
                        )
                      }
                    />
                  </Col>
                ))}
              {currentView === "brand" &&
                filteredData.map((brand) => (
                  <Col key={brand.id} xs={12} md={4} lg={3}>
                    <BrandCard
                      brand={brand}
                      onClick={() =>
                        handleFilterChange("brand", brand.id, brand.name)
                      }
                    />
                  </Col>
                ))}
              {currentView === "brandProducts" &&
                brandProductData.map((product) => (
                  <Col key={product.id} xs={12} md={4} lg={3}>
                    <BrandProductCard
                      product={product}
                      onClick={() =>
                        handleProductClick(product.id, product.slug)
                      }
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

const CategoryCard = ({ category, onClick }) => (
  <div
    className="card mt-3"
    style={{ width: "100%", cursor: "pointer" }}
    onClick={onClick}
  >
    <img
      className="card-img-top"
      src={category.product_image}
      alt="Card image cap"
      style={{ height: "220px", objectFit: "contain" }}
    />
    <div className="card-body">
      <p className="card-text" style={{ textAlign: "center", height: "60px" }}>
        <SafeHtmlComponent htmlContent={category.name} />
      </p>
    </div>
  </div>
);

const SubCategoryCard = ({ subcategory, onClick }) => (
  <div
    className="card mt-3"
    style={{ width: "100%", cursor: "pointer" }}
    onClick={onClick}
  >
    <img
      className="card-img-top"
      src={subcategory.product_image}
      alt="Card image cap"
      style={{ height: "220px", objectFit: "contain" }}
    />
    <div className="card-body">
      <p className="card-text" style={{ textAlign: "center", height: "60px" }}>
        <SafeHtmlComponent htmlContent={subcategory.name} />
      </p>
    </div>
  </div>
);

const ProductTypeCard = ({ productType, onClick }) => (
  <div
    className="card mt-3"
    style={{ width: "100%", cursor: "pointer" }}
    onClick={onClick}
  >
    <img
      className="card-img-top"
      src={productType.product_image}
      alt="Card image cap"
      style={{ height: "220px", objectFit: "contain" }}
    />
    <div className="card-body">
      <p className="card-text" style={{ textAlign: "center", height: "60px" }}>
        <SafeHtmlComponent htmlContent={productType.name} />
      </p>
    </div>
  </div>
);

const BrandCard = ({ brand, onClick }) => (
  <div
    className="card mt-3"
    style={{ width: "100%", cursor: "pointer" }}
    onClick={onClick}
  >
    <img
      className="card-img-top"
      src={brand.product_image}
      alt="Card image cap"
      style={{ height: "220px", objectFit: "contain" }}
    />
    <div className="card-body">
      <p className="card-text" style={{ textAlign: "center", height: "60px" }}>
        <SafeHtmlComponent htmlContent={brand.name} />
      </p>
    </div>
  </div>
);

const BrandProductCard = ({ product, onClick }) => (
  <div
    className="card mt-3"
    style={{ width: "100%", cursor: "pointer" }}
    onClick={onClick}
  >
    <img
      className="card-img-top"
      src={product.image}
      alt="Card image cap"
      style={{ height: "220px", objectFit: "contain" }}
    />
    <div className="card-body">
      <p className="card-text" style={{ textAlign: "center", height: "60px" }}>
        <SafeHtmlComponent htmlContent={product.name} />
      </p>
    </div>
  </div>
);

export default BrandProduct;
