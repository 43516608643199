// import React, { useState, useEffect } from "react";
// import Header from "../../maincomponents/Header";
// import Footer from "../../maincomponents/Footer";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import SafeHtmlComponent from "../main/SafeHtmlComponent";
// import Spinner from "../main/Spinner";

// const Blog = () => {
//   const [blogPosts, setBlogPosts] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchBlogPosts = async () => {
//       try {
//         const response = await axios.get(`https://saaskin.com/api/auth/blogs/`);
//         setBlogPosts(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching blog posts:", error);
//         setLoading(false);
//       }
//     };

//     fetchBlogPosts();
//   }, []);

//   const truncateContent = (content) => {
//     const words = content.split(" ");
//     if (words.length > 20) {
//       return words.slice(0, 20).join(" ") + "...";
//     }
//     return content;
//   };

//   return (
//     <div>
//       {loading && <Spinner />}
//       <Header />
//       <section className="site-section mt-2">
//         <div className="" id="blog-section">
//           <div className="row justify-content-center mb-5" id="blog-section">
//             <div className="col-md-8 text-center">
//               <h2
//                 className="text-black h1 site-section-heading text-center"
//                 style={{ fontFamily: "poppins, sans-serif", fontWeight: "500" }}
//               >
//                 Blog
//               </h2>
//             </div>
//           </div>

//           <div className="row d-flex justify-content-center" id="blog-section">
//             {blogPosts.map((post) => (
//               <div
//                 key={post.id}
//                 className="col-md-6 col-lg-3 mb-5 mb-lg-4 mx-2"
//                 style={{
//                   boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
//                 }}
//               >
//                 <Link to={`/Blogdetail/${post.id}`}>
//                   <div className="h-entry">
//                     <img src={post.image} alt="Image" className="img-fluid" />
//                     <h2
//                       className="font-size-regular"
//                       style={{ color: "black" }}
//                     >
//                       {post.title}
//                     </h2>
//                     <div className="meta ">
//                       <span style={{ color: "black" }}>Post date :</span>
//                       {post.posted_date}
//                       <Link to={`/Blogdetail/${post.id}`}>{post.category}</Link>
//                     </div>
//                     {/* <p style={{ textAlign: "justify" }}>
//                     <SafeHtmlComponent
//                       htmlContent={truncateContent(post.content)}
//                     />
//                   </p> */}
//                     <p>
//                       <Link to={`/Blogdetail/${post.id}`}>
//                         Continue Reading...
//                       </Link>
//                     </p>
//                   </div>
//                 </Link>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//       <Footer />
//     </div>
//   );
// };

// export default Blog;
import React, { useState, useEffect } from "react";
import Header from "../../maincomponents/Header";
import Footer from "../../maincomponents/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Spinner from "../main/Spinner";

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 15;

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await axios.get(`https://saaskin.com/api/auth/blogs/`);
        const sortedPosts = response.data.sort(
          (a, b) => new Date(b.posted_date) - new Date(a.posted_date)
        );
        setBlogPosts(sortedPosts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  // Pagination logic: Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(blogPosts.length / postsPerPage);

  return (
    <div>
      {loading && <Spinner />}
      <Header />
      <section className="site-section mt-2">
        <div className="" id="blog-section">
          <div className="row justify-content-center mb-5" id="blog-section">
            <div className="col-md-8 text-center">
              <h2
                className="text-black h1 site-section-heading text-center"
                style={{ fontFamily: "poppins, sans-serif", fontWeight: "500" }}
              >
                Blog
              </h2>
            </div>
          </div>

          <div className="row d-flex justify-content-center" id="blog-section">
            {currentPosts.map((post) => (
              <div
                key={post.id}
                className="col-md-6 col-lg-3 mb-5 mb-lg-4 mx-2"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <Link to={`/Blogdetail/${post.id}`}>
                  <div className="h-entry">
                    <img src={post.image} alt="Img" className="img-fluid" />
                    <h2
                      className="font-size-regular"
                      style={{ color: "black" }}
                    >
                      {post.title}
                    </h2>
                    <div className="meta">
                      <span style={{ color: "black" }}>Post date:</span>{" "}
                      {post.posted_date}
                      <Link to={`/Blogdetail/${post.id}`}>{post.category}</Link>
                    </div>
                    <p>
                      <Link to={`/Blogdetail/${post.id}`}>
                        Continue Reading...
                      </Link>
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          {/* Pagination controls */}
          <div className="pagination justify-content-center">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                style={{
                  margin: "0 5px",
                  padding: "10px",
                  border: "1px solid #000",
                  backgroundColor: currentPage === index + 1 ? "#000" : "#fff",
                  color: currentPage === index + 1 ? "#fff" : "#000",
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Blog;
