import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom"; // Import useParams to get URL parameters
import axios from "axios";
import Header from "../../maincomponents/Header";
import Footer from "../../maincomponents/Footer";
import SafeHtmlComponent from "../main/SafeHtmlComponent";
import Spinner from "../main/Spinner";

const Blogdetail = () => {
  const { postId } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch blog post details from backend API based on postId
    const fetchBlogPost = async () => {
      try {
        const response = await axios.get(
          `https://saaskin.com/api/auth/blogs/${postId}/`
        );
        setBlogPost(response.data); // Assuming backend returns a single blog post object
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog post:", error);
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [postId]); // Add postId to the dependency array of useEffect

  if (loading) {
    return <Spinner />; // Show the spinner while loading
  }

  return (
    <div>
      <Header />
      <div className="row justify-content-center mb-5" id="blog-section">
        <div className="col-md-8 text-center">
          <h2 className="text-black h1 site-section-heading text-center">
            Blog Detail
          </h2>
        </div>
      </div>
      <div className="col-lg-12 d-flex justify-content-center align-items-center">
        <div
          className="col-md-6 col-lg-8 mb-5 mb-lg-4 mx-2 py-3 d-flex justify-content-center align-items-center"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="h-entryblog container ">
            <h2 className="font-size-regular text-center mt-1">
              {blogPost.title}
            </h2>
            <div className="meta mb-4 px-5">
              <span style={{ fontSize: "20px", fontWeight: "500" }}>
                Post Date :
              </span>
              {blogPost.posted_date}

              <Link to={`/Blogdetail/${blogPost.category}`}>
                {blogPost.category}
              </Link>
            </div>
            <img src={blogPost.image} alt="Image" className="img-fluid" />

            <p className="px-5" style={{ textAlign: "justify" }}>
              <SafeHtmlComponent htmlContent={blogPost.content} />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blogdetail;
